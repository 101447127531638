import './App.css';
import Logo from './components/Logo';
import SignUp from './components/SignUp';

function App() {
  return (
    <div className="global-wrap">
      <SignUp/>
    </div>
  );
}

export default App;
